<div class="card {{cardColor}} m-0 mb-1" [style.color]="textColor">
  <div class="card-content pt-2" *ngIf="order">

    <div class="row m-0 p-0">
      <div class="col-4 p-0 pl-1 m-0">
        <input id="cb_order{{order.id}}" type="checkbox" [checked]="isSelected()" *ngIf="selectable"
               (change)="isSelectedChange()">
        <label class="small-text" [style.color]="textColor" for="cb_order{{order.id}}">&nbsp;{{ order.id }}</label>
        <br/>
        <span class="small-text">
					{{ (order.price + (order.tax_amount ? order.tax_amount : 0)) | currency: 'USD': '$': '1.1-2' }},
          {{ formatOrderDetails(order) }}
				</span>
      </div>
      <div class="col-4 p-0">
        <h6 [innerHTML]="formatState()"></h6>
      </div>
      <div class="col-4 p-0">
				<span class="large-text">
					{{ order.recipe }}
				</span>
        <br/>
        <span class="small-text" ><b>{{ order.customer_name }}</b></span>&nbsp;
        <span class="small-text" *ngIf="order.first_time_user">[FTU]&nbsp;</span>
        <span class="small-text" *ngIf="!order.first_time_user">[RU #{{ order.number_of_orders }}]&nbsp;</span>
      </div>
    </div>

    <div class="row m-0">
      <div class="col-4 p-0 pl-1 m-0">
        <span class="small-text">	Ready: {{ order.ready_pct }}%</span>
      </div>
      <div class="col-4 p-0">
        <span class="small-text">	Start: {{ order.creation_date | kiosk_timezone: 'hh:mm:ss a z': timezone }}</span>
      </div>
      <div class="col-4 p-0">
        <span class="small-text">	Wait Time: {{ order.wait_time ? (order.wait_time / 1000 | time) : 'NaN' }}</span>
      </div>
      <div class="col-4 p-0 pl-1 m-0">
        <span class="small-text">	Complete: {{ order.complete_pct }}%</span>
      </div>
      <div class="col-4 p-0">
				<span
          class="small-text">	{{ order.state == "ACCEPTED" || order.state == "WAITING" || order.state == "PREPARING" ? "Expected Delivery:" : "Delivered:" }}
          {{ order.delivery_time | kiosk_timezone: 'hh:mm:ss a z': timezone }}</span>
      </div>
      <div class="col-4 p-0">
        <span class="small-text"> Processing: {{ getProcessingTime(order) | time }}</span>
      </div>
      <div class="col-4 p-0 pl-1 m-0" *ngIf="order.reordered">
				<span class="small-text"
              matTooltip="Reordered by {{order.reordered_by_user}}"
              [style.color]="'red'">R by {{ order.reordered_by_user }}</span>
      </div>
      <div class="col-4 p-0 pl-1 m-0" *ngIf="order.refund_requested_by">
				<span class="small-text"
              matTooltip="Success: {{order.refunded ? 'Yes': 'No'}}; Amount: {{order.amount_refunded || '?'}}; Date: {{order.refund_request_date | date : 'long'}}"
              [style.color]="'red'">Refunded by {{ order.refund_requested_by }} {{ order.refund_error_message }}</span>
      </div>
      <div class="col-11 p-0 pl-1 m-0 " *ngIf="order.requested_delivery_time" style="margin-left: 15px">
        <span class="small-text">  Requested Delivery Time: </span>
        <span class="small-text"
              [style.color]="'white'"
              [style.background-color]="requestedNextDay(order) ? 'red' : 'darkorange'">{{ order.requested_delivery_time | kiosk_timezone: 'MM/dd/yyyy hh:mm:ss a z' : timezone }}</span>
      </div>
      <div class="col-11 p-0 pl-1 m-0 " *ngIf="order.storage_device" style="margin-left: 15px">
				<span
          class="small-text">  Storage: {{ order.storage_device }} #{{ order.storage_location }}</span>
      </div>
      <div class="col-11 p-0 pl-1 m-0 " *ngIf="order.sms_undelivered"
           style="background-color: palevioletred; margin-left: 15px">
				<span
          class="small-text"> SMS Error</span>
      </div>

    </div>
  </div>
  <div class="card-footer p-1 pt-2" *ngIf="order">
    <div class="row m-0 p-0">
      <div class="col-10 p-0 m-0">
        <span class="small-text text-truncate"
              style="white-space: nowrap; display: block; font-style: italic; overflow: hidden; text-overflow: ellipsis;">
          <ng-container *ngIf="order.jar_name">
            {{ order.jar_name ? (order.jar_name + ": " + (order.jar_place ? order.jar_place : "Home")) : "-" }}
          </ng-container>
          {{ getLastOrderStep(order) }}
        </span>
      </div>
      <div class="col-2 p-0 m-0 pr-1">

        <!--        <ng-container>-->
        <!--          <div class=" dropdown" dropdown [isOpen]="isOpen()" (isOpenChange)="isOpenChange($event)">-->
        <!--            <button-->
        <!--              class=" btn btn-link dropdown-toggle btn-icon dropdown-toggle"-->
        <!--              data-toggle="dropdown"-->
        <!--              dropdownToggle-->
        <!--              type="button"-->
        <!--            >-->
        <!--              <i class=" tim-icons icon-settings-gear-63"> </i>-->
        <!--            </button>-->
        <!--            <div class=" dropdown-menu dropdown-menu-right" *dropdownMenu>-->
        <!--              <a class=" dropdown-item" (click)="summary(order)"> Summary </a>-->
        <!--              <a class=" dropdown-item" (click)="details(order)"> Details </a>-->
        <!--              <a class=" dropdown-item" (click)="ccDetails(order)"> CC Details </a>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </ng-container>-->
      </div>

    </div>
  </div>
</div>
