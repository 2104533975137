import { share } from 'rxjs/operators';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { ProgressLoaderService } from './loader.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export abstract class BaseRequestService {

  constructor(protected http: HttpClient, protected dataService: DataService,
              protected loaderService: ProgressLoaderService) {
  }

  private static startCount = 0;

  public static apiServerUrl(version: number): string {
    return `${environment.SERVER_URL}v${version}/`;
  }

  protected serverUrl(version: number): string {
    return BaseRequestService.apiServerUrl(version);
  }

  protected headers(kioskId?: number): HttpHeaders {
    const kiosk = this.dataService.getProvisioning()?.kiosks?.find(it => +it.kioskId === +kioskId)
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: kiosk?.token || DataService.getToken()
    })
  }

  protected post<T>(url: string, body: any, version: number = 1, kioskId?: number): Observable<T> {
    const options = { headers: this.headers(kioskId) };

    BaseRequestService.startCount++;
    this.loaderService.start();

    const observable: Observable<T> = this.http.post<T>(this.serverUrl(version) + url, body, options).pipe(share());
    observable.subscribe(data => {
      BaseRequestService.startCount--;
      if (BaseRequestService.startCount === 0) {
        this.loaderService.done();
      }
    }, error => {
      BaseRequestService.startCount--;
      if (BaseRequestService.startCount === 0) {
        this.loaderService.done();
      }
    });
    return observable;
  }

  protected put<T>(url: string, body: any, version: number = 1, kioskId?: number): Observable<T> {
    const options = { headers: this.headers(kioskId) };

    BaseRequestService.startCount++;
    this.loaderService.start();

    const observable: Observable<T> = this.http.put<T>(this.serverUrl(version) + url, body, options).pipe(share());
    observable.subscribe(data => {
      BaseRequestService.startCount--;
      if (BaseRequestService.startCount === 0) {
        this.loaderService.done();
      }
    }, error => {
      BaseRequestService.startCount--;
      if (BaseRequestService.startCount === 0) {
        this.loaderService.done();
      }
    });
    return observable;
  }

  protected get2<T>(url: string, search?: any): Observable<T> {
    return this.get(url, search, 2);
  }

  protected getWithKioskToken<T>(kioskId: number, url: string, version: number, search?: any, showLoading: boolean = true): Observable<T> {
    return this.get(url, search, version, kioskId, showLoading);
  }

  protected putWithKioskToken<T>(kioskId: number, version: number, url: string, search?: any): Observable<T> {
    return this.put(url, search, version, kioskId);
  }

  protected postWithKioskToken<T>(kioskId: number, version: number, url: string, body?: any): Observable<T> {
    return this.post(url, body, version, kioskId);
  }


  protected get<T>(url: string, search?: any, version: number = 1, kioskId?: number, showLoading: boolean = true): Observable<T> {
    const options = { headers: this.headers(kioskId), params: search };

    if (showLoading) {
      BaseRequestService.startCount++;
      this.loaderService.start();
    }

    const observable: Observable<T> = this.http.get<T>(this.serverUrl(version) + url, options).pipe(share());
    observable.subscribe(data => {
      if (showLoading) {
        BaseRequestService.startCount--;
        if (BaseRequestService.startCount === 0) {
          this.loaderService.done();
        }
      }
    }, error => {
      if (showLoading) {
        BaseRequestService.startCount--;
        if (BaseRequestService.startCount === 0) {
          this.loaderService.done();
        }
      }
    });
    return observable;
  }


  protected del<T>(url: string, search?: any, version: number = 1): Observable<T> {
    const options = { headers: this.headers(), params: search };

    BaseRequestService.startCount++;
    this.loaderService.start();

    const observable: Observable<T> = this.http.delete<T>(this.serverUrl(version) + url, options).pipe(share());
    observable.subscribe(data => {
      BaseRequestService.startCount--;
      if (BaseRequestService.startCount === 0) {
        this.loaderService.done();
      }
    }, error => {
      BaseRequestService.startCount--;
      if (BaseRequestService.startCount === 0) {
        this.loaderService.done();
      }
    });
    return observable;
  }

}
