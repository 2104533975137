<div class="card {{cardColor}} m-0" [style.color]="textColor">
  <div class="card-content p-2">
    <div class="row">
      <div class="col-6">
				<span class="med-text"
              matTooltip="{{device.fuse_status}} {{device.notes}}">{{ device.name.replace(replaceName, '') }}</span>
      </div>
      <div class="col-6 text-right">
				<span class=" badge " [ngClass]="!isJarClean() ? 'badge-error': 'badge-success'" *ngIf="isJar()"
              matTooltip="Allergens: {{device.jar_cleaning_state.allergens}}
							 Last Use: {{device.jar_cleaning_state.last_use_date | los_angeles: 'MM/dd/yyyy hh:mm:ss a'}}
							 Sanitizing: {{device.jar_cleaning_state.last_sanitizing_date | los_angeles: 'MM/dd/yyyy hh:mm:ss a'}}">{{ isJarClean() ? "CLEAN" : "USED" }}</span>
        &nbsp;
        <ng-container *ngIf="device.hatch_cover_status">
				<span class=" badge "
              [ngClass]=" device.hatch_cover_status.status == 'close' ? 'badge-error': 'badge-success'"
              matTooltip="{{device.hatch_cover_status.status_change_date | los_angeles: 'MM/dd/yyyy hh:mm:ss a'}}">{{ device.hatch_cover_status.status }}</span>
          &nbsp;
        </ng-container>
        <span class=" badge " [ngClass]="getState() !== 'ON' ? 'badge-error': 'badge-warning'"
              matTooltip="{{spill != true && spill != false ? spill: ''}}">{{ getState() }}</span>


      </div>
    </div>

    <div class="row m-0 p-0">

      <span *ngIf="device.heartbeat_timestamp && isOffline()" class="error-bg p-1"
            style="font-size: 11px; font-weight: bold">
					Offline
			</span>

      <span *ngIf="device.heartbeat_timestamp && !isOffline()" class="p-1"
            style="font-size: 11px; font-weight: bold">
					Online
			</span>

      <div *ngFor="let ing of device.ingredients" class="col-{{colSize}} p-1">
        <div class=" card {{subCardColor}} m-0 p-1">

          <div class="text-center">
            <span style="font-size: 11px; font-weight: bold">{{ ing.name }}</span>
            <span style="font-size: 16px; font-weight: bold; color: red" *ngIf="ing.disabled">&nbsp;⏻</span>
          </div>
          <div class="row m-0 p-0">
            <div class="col-5 p-0 m-0">
            </div>
            <div class="col-7 p-0 m-0" style="width: 60px; height: 40px;">
              <ngx-gauge type="semi"
                         size="60" backgroundColor="#bbbbbb"
                         animate="false"
                         append="%"
                         [value]="getIngredientPercent(ing)"
                         [thresholds]="thresholdConfig">
              </ngx-gauge>
            </div>
            <div class="col-12">
              {{ getLastIngredientStep(device, ing) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
