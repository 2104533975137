import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output } from '@angular/core';
import { DeviceSnapshot, IngredientSnapshot, KioskNOC } from '../../model/noc';
import { SweetAlert2LoaderService } from '@sweetalert2/ngx-sweetalert2';
import { AppComponent } from '../../app.component';
import { DiagnosticsStepSequenceBean } from '../../model/model';
import { StoreManagerService } from '../../services/store-manager.service';
import { DataService } from 'src/app/services/data.service';
import { AngularFireDatabaseNocService } from '../../services/fire.service';
import moment from 'moment-timezone';

@Component({
    selector: 'app-device',
    templateUrl: './device.component.html',
})
export class DeviceComponent implements OnInit {

    static isOpen: boolean[] = [];

    @Input() device: DeviceSnapshot;
    @Input('noc') kioskNOC: KioskNOC;
    @Input() replaceName: string;
    @Input() kioskId: number;
    @Input() colSize: number = 12;
    @Input() stepsList: DiagnosticsStepSequenceBean[];
    @Input() spill: boolean;
    @Input() isDispenser: boolean;
    @Input() isJumbotron: boolean;
    @Input() cardColor = '';
    @Input() textColor = '#525f7f';
    @Input() subCardColor = 'grayer';

    @Output() onProvisionIngredients = new EventEmitter<DeviceSnapshot>();
    @Output() onDisableIngredients = new EventEmitter<DeviceSnapshot>();
    @Output() onDeactivateDevice = new EventEmitter<DeviceSnapshot>();
    @Output() onAnalytics = new EventEmitter<DeviceSnapshot>();
    @Output() onViewImage = new EventEmitter<DeviceSnapshot>();

    DataService = DataService;

    thresholdConfig = {
        '0': { color: 'darkred' },
        '10': { color: 'orange' },
        '25': { color: 'green' }
    };

    @Input() viewImageCommand: Boolean = false;
    @Input() analyticsCommand: Boolean = false;
    @Input() restartCommand: Boolean = true;
    @Input() refillCommand: Boolean = true;
    @Input() unlockCommand: Boolean = false;

    @Input() setLevelsCommand: Boolean = true;
    @Input() disableIngredientsCommand: Boolean = false;

    @Input() deliverCommand: Boolean = false;
    @Input() refillCommandText = 'Refill';
    @Input() deactivateDeviceCommand: Boolean = false;


    constructor(
        private storeManagerService: StoreManagerService,
        private sweetAlert2LoaderService: SweetAlert2LoaderService,
        protected nocDatabaseService: AngularFireDatabaseNocService,
        @Inject(forwardRef(() => AppComponent)) private app: AppComponent) {
    }

    ngOnInit() {
    }

    getIngredientPercent(ingredient: IngredientSnapshot) {
        return Math.round(ingredient.current_amount <= 0 ? 0 : ingredient.current_amount / ingredient.max_amount * 100);
    }

    getLastIngredientStep(device: DeviceSnapshot, ingredient: IngredientSnapshot) {
        if (this.kioskNOC && this.kioskNOC.order_steps_noc) {

            const orders = this.kioskNOC.order_steps_noc.value;
            for (const id in orders) {
                if (this.isIterable(orders[id].steps)) {
                    for (const step of orders[id].steps) {
                        if (step && step.device_name == device.name && step.state != 'WAITING') {
                            if (step.process.indexOf(ingredient.name) > 0) {
                                let process = step.process.replace(device.name, '').replace(' -> ', '');
                                if (step.dispensed_weight) {
                                    process += ' Response {' + step.dispensed_weight + '}';
                                }
                                return process;
                            }
                        }
                    }
                }
            }
        }
        return '';
    }

    isIterable(obj) {
        return obj && typeof obj[Symbol.iterator] === 'function';
    }

    restart(device: DeviceSnapshot) {
    }


    unlock(device: DeviceSnapshot) {
    }

    refill(device: DeviceSnapshot) {
    }

    setIngredientQuantity(device: DeviceSnapshot) {
    }

    isOpen(): boolean {
        return DeviceComponent.isOpen[this.device.id];
    }

    isOpenChange($event: boolean) {
        DeviceComponent.isOpen[this.device.id] = $event;
    }

    isJar(): boolean {
        return this.device?.jar_cleaning_state != null;
    }

    isJarClean(): boolean {
        return this.device?.jar_cleaning_state?.clean == true;
    }

    getState() {
        return this.device.invalid ? 'INVALID' : this.getStateString();
    }

    private getStateString() {
        if (this.device.disabled) {
            return 'OFF';
        }
        switch (this.device.state) {
            case 'OUT_OF_SERVICE':
                return 'OOS';
            case 'ACTIVE':
                return 'ON';
            case 'FAILED':
                return 'FAIL';
            case 'PROVISIONED':
                return 'PROV';
        }
    }

    isValidStep(step: DiagnosticsStepSequenceBean): boolean {
        if (!step.allowForNOC) {
            return false;
        }
        return step.steps.find(it => {
            return it.step.deviceName == this.device.name;
        }) != null;
    }

    formatFailures() {

        let className = '';
        if (this.device.today_errors_count > 5) {
            className = 'warning-bg';
        }
        if (this.device.today_errors_count > 10) {
            className = 'error-bg';
        }
        const up = this.device.four_days_errors_average > this.device.four_days_errors_average_previous;
        const arrow = up ? '▲' : '▼';
        const colorClass = up ? 'error-color' : 'green-color';
        return `<span class='${className}'>
                  <span class='${colorClass}'>${arrow}</span>&nbsp;Retries<br />
                    ${this.device.today_errors_count} (${this.device.today_errors_pct}%)
                </span>`;
    }

    formatYesterdayFailures() {
        return this.device.yesterday_errors_count != null ? `${this.device.yesterday_errors_count} (${this.device.yesterday_errors_pct}%)` : '';
    }

    hasValidSteps(): boolean {
        if (this.stepsList) {
            for (let step of this.stepsList) {
                if (this.isValidStep(step)) {
                    return true;
                }
            }
        }
        return false;
    }

    disableIngredients(device: DeviceSnapshot) {
        this.onDisableIngredients?.emit(device);
    }

    provisionIngredients(device: DeviceSnapshot) {
        this.onProvisionIngredients?.emit(device);
    }

    analytics(device: DeviceSnapshot) {
        this.onAnalytics?.emit(device);
    }

    hatchStatus(device: DeviceSnapshot) {
        if (device.hatch_cover_status) {
            return `Status: ${device.hatch_cover_status.status}`;
        } else {
            return '';
        }
    }

    temperature(device: DeviceSnapshot) {

        if (device.temperature_values && device.temperature_values.length >= 3) {

            const up = device.temperature_values[2] - device.temperature_values[1] >= 1;
            const down = device.temperature_values[1] - device.temperature_values[2] >= 1;
            const arrow = up ? '▲' : (down ? '▼' : '');
            const colorClass = up ? 'error-color' : 'green-color';

            const th = device.name.indexOf('Refr') >= 0 ? 37 : -5;
            return `<span>
                  <span class='${colorClass}'>${arrow}</span>Last 3 °F <br />
                    ${this.formatTemperature(this.toFahrenheit(device.temperature_values[0]), th)} 
                    ${this.formatTemperature(this.toFahrenheit(device.temperature_values[1]), th)} 
                    ${this.formatTemperature(this.toFahrenheit(device.temperature_values[2]), th)} 
                </span>`;

        } else {
            return '';
        }
    }

    formatTemperature(t: number, th: number) {
        const colorClass = t > th ? 'error-color' : '';
        return `<span class='${colorClass}'>${t.toFixed(1)}</span>`;
    }

    toFahrenheit(cTemp: number) {
        return cTemp * 9 / 5 + 32;
    }

    viewImage(device: DeviceSnapshot) {
        this.onViewImage.emit(device);
    }

    deactivateDevice(device: DeviceSnapshot) {
        this.onDeactivateDevice?.emit(device);
    }

    orderedSteps() {
        return this.stepsList ? this.stepsList.sort((i1, i2) => i1.description.localeCompare(i2.description)) : [];
    }

    isOffline() {
        const dt = moment().valueOf() - (this.device.heartbeat_timestamp ?? 0);
        return dt > 20_000;
    }
}
