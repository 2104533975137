import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output } from '@angular/core';
import { KioskNOC, OrderSnapshot } from '../../model/noc';
import { SweetAlert2LoaderService } from '@sweetalert2/ngx-sweetalert2';
import { AppComponent } from '../../app.component';
import { OrderService } from '../../services/order.service';
import { Router } from '@angular/router';
import { StoreManagerService } from '../../services/store-manager.service';
import moment from 'moment-timezone';
import { DataService } from 'src/app/services/data.service';
import { BaseOrderComponent } from "../base-order.component";

@Component({
    selector: 'app-order',
    templateUrl: 'order.component.html',
})
export class OrderComponent extends BaseOrderComponent implements OnInit {
    static isOpen: boolean[] = [];
    static isPushedWait: boolean[] = [];
    static isPushedProcessing: boolean[] = [];
    static isSelected: boolean[] = [];

    @Input() order: OrderSnapshot;
    @Input() noc: KioskNOC;
    @Input() kioskId: number;
    @Input() kioskName: string;
    @Input() prefix: string;
    @Input() timezone: string;
    @Input() cardColor = 'grayish';
    @Input() textColor = '#525f7f';
    @Input() stateClassName = 'color-black';
    @Input() selectable = false;

    @Output() onContactUser = new EventEmitter<{ order: OrderSnapshot, kioskId: number, preset: string }>();
    @Output() onShowSummary = new EventEmitter<OrderSnapshot>();
    @Output() onViewImage = new EventEmitter<OrderSnapshot>();
    @Output() onCCDetails = new EventEmitter<OrderSnapshot>();

    DataService = DataService;

    constructor(
        private router: Router,
        // private pushNotifications: PushNotificationsService,
        storeManagerService: StoreManagerService,
        sweetAlert2LoaderService: SweetAlert2LoaderService,
        orderService: OrderService,
        @Inject(forwardRef(() => AppComponent)) app: AppComponent) {
        super(app, orderService, storeManagerService, sweetAlert2LoaderService);
    }

    ngOnInit() {
    }

    public static getProcessingTime(order: OrderSnapshot): number {
        if (order.ready_date) {
            return ((order.ready_date - order.start_date) / 1000);
        }
        if (order.delivery_time) {
            return ((order.delivery_time - order.start_date) / 1000);
        }
        return ((new Date().getTime() - order.start_date) / 1000);
    }

    getLastOrderStep(order: OrderSnapshot) {
        let result;
        if (this.noc && this.noc.order_steps_noc) {
            const orders = this.noc.order_steps_noc.value;
            if (orders[order.id]) {
                if (this.isIterable(orders[order.id].steps)) {
                    for (const step of orders[order.id].steps) {
                        if (step && step.state != 'WAITING') {
                            result = step.process;
                        }
                    }
                }
            }
        }
        return result;
    }

    isIterable(obj) {
        return obj && typeof obj[Symbol.iterator] === 'function';
    }

    pickup(order: OrderSnapshot) {
        super.showPickupConfirmation(this.kioskId, order.id, order.recipe);
    }

    isOpen(): boolean {
        return OrderComponent.isOpen[this.prefix + this.order.id];
    }

    isOpenChange($event: boolean) {
        OrderComponent.isOpen[this.prefix + this.order.id] = $event;
    }

    isSelected(): boolean {
        return OrderComponent.isSelected[this.prefix + this.order.id];
    }

    isSelectedChange() {
        OrderComponent.isSelected[this.prefix + this.order.id] = !OrderComponent.isSelected[this.prefix + this.order.id];
    }

    setSelected(selected: boolean) {
        OrderComponent.isSelected[this.prefix + this.order.id] = selected;
    }

    details(order: OrderSnapshot) {

        const url = this.router.serializeUrl(this.router.createUrlTree(['orders', 'details', order.id]));
        window.open(url, '_blank');
    }

    ccDetails(order: OrderSnapshot) {
        this.onCCDetails.emit(order);
    }

    summary(order: OrderSnapshot) {
        this.onShowSummary.emit(order);
    }

    formatState() {
        let className = this.stateClassName;

        if (this.order.state == 'ACCEPTED') {
            if (OrderComponent.getProcessingTime(this.order) > 3 * 60) {
                className = 'warning-bg';
            }
        }
        if (OrderComponent.getProcessingTime(this.order) > 10 * 60 && this.order.state == 'PREPARING') {
            className = 'error-bg';

            if (!OrderComponent.isPushedProcessing[this.prefix + this.order.id]) {
                OrderComponent.isPushedProcessing[this.prefix + this.order.id] = true;
                this.push('Order takes more than 10 minutes to process: ' + this.order.recipe);
            }
        }

        if (this.order.wait_time / 1000 > 60 && (this.order.state == 'WAITING' || this.order.state == 'ACCEPTED')) {
            if (!OrderComponent.isPushedWait[this.prefix + this.order.id]) {
                OrderComponent.isPushedWait[this.prefix + this.order.id] = true;
                this.push('Order is waiting for more than 1 minute: ' + this.order.recipe);
            }
        }

        if (this.order.state == 'COMPLETED') {
            className = 'green-bg';
        }

        if (this.order.state == 'CANCELLED') {
            className = 'error-bg';
        }

        return `<span class='${className}'>${this.order.state}</span>`;
    }

    contactUser(order: OrderSnapshot) {
        this.onContactUser.emit({ order, kioskId: this.kioskId, preset: null });
    }

    refund(order: OrderSnapshot) {
        this.onContactUser.emit({ order, kioskId: this.kioskId, preset: 'refund' });
    }

    reorder(order: OrderSnapshot) {
        this.onContactUser.emit({ order, kioskId: this.kioskId, preset: 'reorder' });
    }

    cancel(order: OrderSnapshot) {
        this.onContactUser.emit({ order, kioskId: this.kioskId, preset: 'cancel' });
    }

    push(msg: string) {
    }

    viewImage(device: OrderSnapshot) {
        this.onViewImage.emit(device);
    }

    formatOrderDetails(order: OrderSnapshot) {
        const list = [order.client_type, order.tender_name, order.payment_type, order.square_card_entry_method, order.coupon];
        return list.filter(it => it).join(', ');
    }

    requestedNextDay(order: OrderSnapshot): boolean {
        if (!order.requested_delivery_time || !this.timezone) {
            return false;
        }
        return moment(order.requested_delivery_time).tz(this.timezone).dayOfYear() != moment(order.creation_date).tz(this.timezone).dayOfYear();
    }

    getProcessingTime(order: OrderSnapshot) {
        return OrderComponent.getProcessingTime(order);
    }

}
